@import '../../styles/customMediaQueries.css';

.root {
  margin: 0;
  padding: 0;
  border: none;
}

.list {
  margin: 0;
}

.twoColumns {
  @media (--viewportMedium) {
    columns: 2;
  }
}

.item {
  padding: 4px 0 2px 0;

  /* Fix broken multi-column layout in Chrome */
  page-break-inside: avoid;

  @media (--viewportMedium) {
    padding: 6px 0 2px 0;
  }
}

.limitMessage {
  color: orange;
}

.helperLink {
  color: cornflowerblue;
  text-decoration: underline;
  font-weight: 800;
}
