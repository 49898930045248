.imageCropperWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.imageCropperModal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.imageCropperContent {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
}

.imageCropperHeader {
  margin-bottom: 20px;
}

.imageCropperTitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.imageCropperFooter {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.buttonPrimary {
  background-color: #0070f3;
  color: white;
}

.buttonSecondary {
  background-color: #e0e0e0;
  color: #333;
}

.croppedImage {
  max-width: 300px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
